<template>
    <div class="content">
        <headerText></headerText>
        <div class="photo_img">
            <span>最美课本</span>
        </div>
        <div class="list_video">
            <div class="list_videos">
                <ul>
                    <li>
                        <div class="video_one">
                            <div class="video_bg">
                                <p class="video_img" @click="playVideo" v-show="!isShow">
                                    <span class="video_play"></span>
                                </p>
                                <div class="video_open" v-show="isShow">
                                    <video
                                        ref="videoPlayer"
                                        preload
                                        disablepictureinpicture
                                        controlslist="nodownload"
                                        controls
                                        width="800" height="350"
                                        src="http://file.zjx.com/mp4/1568712089502.mp4">
                                    </video>
                                </div>
                            </div>

                            <div class="video_title">
                                <p class="video_hs fb">最美课本宣传片</p>
                                <p class="video_text fb">美育融合化学习平台</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <footerText></footerText>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                isShow: false
            };
        },
        mounted() {
            if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
                this.$router.push('/classroomEn')
            }
        },
        methods: {
            playVideo(){
                this.isShow = true;
                this.$refs.videoPlayer.play();
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../../assets/css/classroom.scss';
</style>
